import payload_plugin_w0HDFr_j2IsnaU6rXjh462d6_UAIdSXuZM71S4bTz44 from "/var/www/parsitrip.com/front/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_nxV0uoT_6_gPB1dU0MYzGyT37wpgvKl27Q9vMDMXvnQ from "/var/www/parsitrip.com/front/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4.2__io_3lzr6qtn5efmlr4a4ubenaspqi/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_EyafXVr87vWTYlIQQmHS5Q4XOtxA3rMUWr_GnougU0k from "/var/www/parsitrip.com/front/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4.2__io_3lzr6qtn5efmlr4a4ubenaspqi/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_3Ijd8fXXbLvnSmyYlpP1BO_z7NztvbgcuZJkIEsnF0M from "/var/www/parsitrip.com/front/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4.2__io_3lzr6qtn5efmlr4a4ubenaspqi/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_TJBuJjhtA1_40kuDLRBnXBo_p_ZcUivlD_DY6ZS7aXo from "/var/www/parsitrip.com/front/node_modules/.pnpm/nuxt-site-config@3.1.6_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_PwVnJUFWedTOre5Uoum5DpyrXr2eQ3JwyUqwc1w2zFI from "/var/www/parsitrip.com/front/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4.2__io_3lzr6qtn5efmlr4a4ubenaspqi/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_tak4mIczpV6XU3v68m5P3infcRAGvtOvZF6tZ3sm0Uw from "/var/www/parsitrip.com/front/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4.2__io_3lzr6qtn5efmlr4a4ubenaspqi/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_CcnPktjMAPN4AthXbU6neWUV7OPCj7b9hDvEAKZ1BcU from "/var/www/parsitrip.com/front/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4.2__io_3lzr6qtn5efmlr4a4ubenaspqi/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_mBnihSOgS6jIRTpuQSvIqMI4CX_bGxC8tDxkHWiz0Ck from "/var/www/parsitrip.com/front/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4.2__io_3lzr6qtn5efmlr4a4ubenaspqi/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_VOBLJeFFdlw9Z7wNERmNnNdg4S5xCSiHp3LAaOiknDo from "/var/www/parsitrip.com/front/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/var/www/parsitrip.com/front/.nuxt/components.plugin.mjs";
import prefetch_client_1bxSQNBLGlMOOVBx_s8P_JnQWkDF5m__PY_1MiLQCJk from "/var/www/parsitrip.com/front/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4.2__io_3lzr6qtn5efmlr4a4ubenaspqi/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_C24GcIKjcI2zsa8A86om0L2LZjx1chWtzYxD11T7Txg from "/var/www/parsitrip.com/front/.nuxt/pwa-icons-plugin.ts";
import siteConfig_dsrMilOLfOM2CLRy1OOWJ9WoJvcaQY5aSVlklqe8s8M from "/var/www/parsitrip.com/front/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.36.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_QuSxBU_SVVDfkHTzzXaJcTACMwwRH9gWPu1zLdPOcgI from "/var/www/parsitrip.com/front/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.36.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_vWlBgqKMj1WgkQsSJwx52xTBp_5lJpr8iHoo3FV92k4 from "/var/www/parsitrip.com/front/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.36.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import colors_tfb8_4bMfvTpkvc6GQnSmkG9KSzIKU7_h1WTM7svqio from "/var/www/parsitrip.com/front/node_modules/.pnpm/@nuxt+ui@3.0.2_@babel+parser@7.26.10_change-case@5.4.4_db0@0.3.1_embla-carousel@8.5.2_ioredis_hohwcm2xl3v7ce5ju2yhcxcwqm/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_hfhi7EvGMZ1W8_oCOXoqkQnkKm8rhjarvv8OwRMckSo from "/var/www/parsitrip.com/front/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_u5mEV7wUq4Od5nCJ_VeopMJ2MpAj4upYtk96bWDObJI from "/var/www/parsitrip.com/front/node_modules/.pnpm/@nuxt+icon@1.11.0_magicast@0.3.5_vite@6.2.2_@types+node@22.13.10_jiti@2.4.2_lightningcss@1.29_2fbrurz7eprxhzty35wucjv67u/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import device_detection_ME3xO91aNmfryxRRbaON9T8bOhyfojmCoK_lIrsscIg from "/var/www/parsitrip.com/front/plugins/device-detection.ts";
import moment_timezone_BjAmxaUP0f3GLerhouI13P2IwAFPnY_0UQ2I5aeEmsE from "/var/www/parsitrip.com/front/plugins/moment-timezone.ts";
import jalali_moment_wB_Hon_hmyBhzxDGYUJBQ_ttUlPPLk2qp30qG4iz_1o from "/var/www/parsitrip.com/front/plugins/jalali-moment.ts";
import plugin_eeWtiv_GpXmHLPPjyvRAK59cWop3DPpT1F_8XjmGp3k from "/var/www/parsitrip.com/front/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescr_tqvcoghzevnzczatfgkdcnnlqm/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import exposeIpAndIranCheck_MRVRst5HiiL0_jrvDoZZMJr5mSZazGwC1Dw_CcYDDXk from "/var/www/parsitrip.com/front/plugins/exposeIpAndIranCheck.ts";
import pwa_client_7tmYNfZ_pv9l8fIXQ9o2UulpcTU4JI608OXbk9cFUrc from "/var/www/parsitrip.com/front/node_modules/.pnpm/@vite-pwa+nuxt@1.0.0_magicast@0.3.5_vite@6.2.2_@types+node@22.13.10_jiti@2.4.2_lightningcss@1_kozqw2izng4tyfngh5ijgipgxq/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import _0_routeRules_K0LXX3K4pqPsK9KeIYNEnd0ietrbpvd6FcKNj0X0exI from "/var/www/parsitrip.com/front/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.36.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/0.routeRules.js";
import defaults_aMJANx05_4N9jeeISdeVOwxNLCnpv_Lgwk6ujewrhv8 from "/var/www/parsitrip.com/front/node_modules/.pnpm/nuxt-seo-utils@7.0.4_magicast@0.3.5_rollup@4.36.0_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaults.js";
export default [
  payload_plugin_w0HDFr_j2IsnaU6rXjh462d6_UAIdSXuZM71S4bTz44,
  revive_payload_client_nxV0uoT_6_gPB1dU0MYzGyT37wpgvKl27Q9vMDMXvnQ,
  unhead_EyafXVr87vWTYlIQQmHS5Q4XOtxA3rMUWr_GnougU0k,
  router_3Ijd8fXXbLvnSmyYlpP1BO_z7NztvbgcuZJkIEsnF0M,
  _0_siteConfig_TJBuJjhtA1_40kuDLRBnXBo_p_ZcUivlD_DY6ZS7aXo,
  payload_client_PwVnJUFWedTOre5Uoum5DpyrXr2eQ3JwyUqwc1w2zFI,
  navigation_repaint_client_tak4mIczpV6XU3v68m5P3infcRAGvtOvZF6tZ3sm0Uw,
  check_outdated_build_client_CcnPktjMAPN4AthXbU6neWUV7OPCj7b9hDvEAKZ1BcU,
  chunk_reload_client_mBnihSOgS6jIRTpuQSvIqMI4CX_bGxC8tDxkHWiz0Ck,
  plugin_vue3_VOBLJeFFdlw9Z7wNERmNnNdg4S5xCSiHp3LAaOiknDo,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_1bxSQNBLGlMOOVBx_s8P_JnQWkDF5m__PY_1MiLQCJk,
  pwa_icons_plugin_C24GcIKjcI2zsa8A86om0L2LZjx1chWtzYxD11T7Txg,
  siteConfig_dsrMilOLfOM2CLRy1OOWJ9WoJvcaQY5aSVlklqe8s8M,
  inferSeoMetaPlugin_QuSxBU_SVVDfkHTzzXaJcTACMwwRH9gWPu1zLdPOcgI,
  titles_vWlBgqKMj1WgkQsSJwx52xTBp_5lJpr8iHoo3FV92k4,
  colors_tfb8_4bMfvTpkvc6GQnSmkG9KSzIKU7_h1WTM7svqio,
  plugin_client_hfhi7EvGMZ1W8_oCOXoqkQnkKm8rhjarvv8OwRMckSo,
  plugin_u5mEV7wUq4Od5nCJ_VeopMJ2MpAj4upYtk96bWDObJI,
  device_detection_ME3xO91aNmfryxRRbaON9T8bOhyfojmCoK_lIrsscIg,
  moment_timezone_BjAmxaUP0f3GLerhouI13P2IwAFPnY_0UQ2I5aeEmsE,
  jalali_moment_wB_Hon_hmyBhzxDGYUJBQ_ttUlPPLk2qp30qG4iz_1o,
  plugin_eeWtiv_GpXmHLPPjyvRAK59cWop3DPpT1F_8XjmGp3k,
  exposeIpAndIranCheck_MRVRst5HiiL0_jrvDoZZMJr5mSZazGwC1Dw_CcYDDXk,
  pwa_client_7tmYNfZ_pv9l8fIXQ9o2UulpcTU4JI608OXbk9cFUrc,
  _0_routeRules_K0LXX3K4pqPsK9KeIYNEnd0ietrbpvd6FcKNj0X0exI,
  defaults_aMJANx05_4N9jeeISdeVOwxNLCnpv_Lgwk6ujewrhv8
]